<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" scrollable persistent width="1300px" @close="close">
        <v-card>
          <v-card-title class="justify-center py-3">
            {{ object.titulo }}
            <v-spacer></v-spacer>
            <v-btn
              v-auth-acl="'gh-ind-mis_ind-download'"
              :loading="loading"
              :disabled="loading"
              color="primary"
              class="white--text"
              @click="exportar(object.codigo)"
            >
              Descargar PDF
              <v-icon right dark>mdi-cloud-download</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text class="pt-8">
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field dense v-model="object.procesoEvaluado" label="Proceso Evaluado" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  dense
                  v-model="object.nombreIndicador"
                  label="Nombre del Indicador"
                  solor
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" class="py-0">
                <v-text-field dense v-model="object.queBusca" label="Que se busca" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field dense v-model="object.unidadMedida" label="Unidad de Medida" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" class="py-0">
                <v-text-field dense v-model="object.formaCalculo" label="Formula de Cálculo" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0 pt-3">
                <v-textarea
                  dense
                  outlined
                  v-model="object.detalleFormula"
                  label="Detalle de la fórmula"
                  readonly
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <span class="body-1 g">Consideraciones de Gestión</span>
                <v-text-field dense class="ma-0 pa-0" v-model="object.consideracion1" readonly>
                  <template v-slot:prepend>
                    <v-icon color="green">
                      mdi-circle
                    </v-icon>
                  </template>
                </v-text-field>
                <v-text-field dense class="ma-0 pa-0" v-model="object.consideracion2" readonly>
                  <template v-slot:prepend>
                    <v-icon color="amber">
                      mdi-circle
                    </v-icon>
                  </template>
                </v-text-field>
                <v-text-field dense class="ma-0 pa-0" v-model="object.consideracion3" readonly>
                  <template v-slot:prepend>
                    <v-icon color="red">
                      mdi-circle
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-textarea dense outlined v-model="object.fuenteDatos" label="Fuente de Datos" readonly></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-textarea
                  dense
                  outlined
                  v-model="object.datosHistoricos"
                  label="Datos Históricos"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  dense
                  v-model="object.metaPropuesta"
                  label="Meta Propuesta del Indicador"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" class="py-0">
                <v-text-field
                  dense
                  v-model="object.responsablesMetas"
                  label="Responsable de Metas"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  dense
                  v-model="object.frecuenciaMedicion"
                  label="Frecuendia de Medición"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  dense
                  v-model="object.frecuenciaEvaluacion"
                  label="Frecuendia de Medición"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field dense v-model="object.responsableMedicion" label="Responsable de Medicón" readonly>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field dense v-model="object.responsableEvalucion" label="Responsable de Evaluación" readonly>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" class="py-0">
                <v-textarea dense outlined v-model="object.observaciones" label="Observaciones" readonly></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field dense v-model="object.elaboro" label="Elaboró" readonly> </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field dense v-model="object.reviso" label="Revisó" readonly> </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field dense v-model="object.aprobo" readonly label="Aprobó"> </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn small color="error" @click="$emit('closeIndiView')">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'IndicadorView',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    object: {
      type: Object,
      default: () => ({
        codigo: '',
        procesoEvaluado: '',
        titulo: '',
        nombreIndicador: '',
        queBusca: '',
        unidadMedida: '',
        formaCalculo: '',
        detalleFormula: '',
        consideracion1: '',
        consideracion2: '',
        consideracion3: '',
        fuenteDatos: '',
        datosHistoricos: '',
        metaPropuesta: '',
        responsablesMetas: '',
        frecuenciaMedicion: '',
        frecuenciaEvaluacion: '',
        responsableMedicion: '',
        responsableEvalucion: '',
        observaciones: '',
        elaboro: '',
        reviso: '',
        aprobo: ''
      })
    }
  },
  data: () => ({
    loading: false,
    matrizService: null
  }),
  methods: {
    close() {
      this.$emit('closeIndiView');
    },
    async exportar(codigo) {
      this.loading = true;
      const token = localStorage.getItem('token');
      const empresa = decryptAES(localStorage.getItem('emp'));
      await this.matrizService.downloadResource(
        'descargarindicador',
        {
          idempresa: empresa,
          token: token,
          idhn: codigo
        },
        `HN-${codigo}-${moment().format('YYYYMMDDHHMM')}.pdf`
      );
      this.loading = false;
    }
  },
  async created() {
    this.matrizService = this.$httpService(new MatrizService(), this);
  }
};
</script>
