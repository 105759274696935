<template>
  <div>
    <div class="mt-4 text-center">
      <v-dialog v-model="dialog" scrollable persistent width="1700" @close="close">
        <v-card flat>
          <v-card-title class="justify-center uppercase py-3">
            DASHBOARD DEL INDICADOR {{ object.indicador }}</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <iframe id="container" style="width: 100%;height: 100vh;" :src="object.url"></iframe>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="error" text @click="$emit('closeDashView')">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    object: {
      type: Object,
      default: () => ({
        indicador: '',
        url: ''
      })
    }
  },
  methods: {
    close() {
      this.$emit('closeDashView');
    }
  }
};
</script>
