<template>
  <div class="pt-3 pb-0 px-1">
    <FichaTecnicaFormulario
      :dialogEdit="dialogEdit"
      :preview="previewFicha"
      @closeEditView="closeEditView()"
      :objectEditIndi="objectEditIndi"
    ></FichaTecnicaFormulario>
    <v-row class="mt-5 mb-0 d-flex justify-end">
      <v-col cols="6" sm="4" md="3" class="py-0">
        <v-select
          dense
          hide-details
          class="mr-4"
          v-model="anio"
          :items="listAnios"
          @change="cambioAnio"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Año"
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3" md="3" class="py-0">
        <v-select
          dense
          multiple
          v-model="areas"
          :items="itemsAreas"
          @blur="initialize"
          no-data-text="No se encontró información"
          :return-object="false"
          item-text="text"
          item-value="value"
          label="Área"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllAreas" @mousedown.prevent>
              <v-list-item-action>
                <v-icon color="primary">{{ iconCheckboxAllAreas }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> TODOS </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <template v-if="areas.length == itemsAreas.length">
              <span v-if="index === 0">TODOS</span>
            </template>
            <template v-else>
              <span v-if="index > 0">,</span><span>{{ item.value }}</span>
            </template>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="indicadores"
      class="border"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
      :height="height"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">INDICADORES</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" vertical></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" fab outlined small @click="initialize" v-bind="attrs" v-on="on">
                <v-icon>mdi-table-refresh</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <v-tooltip v-auth-acl="'gh-ind-mis_ind-export'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" fab dark small class="mr-2" @click="exportarbase" v-bind="attrs" v-on="on">
                <v-icon dark>mdi-cloud-download</v-icon>
              </v-btn>
            </template>
            <span>Exportar BD</span>
          </v-tooltip>
          <v-tooltip v-auth-acl="'gh-ind-mis_ind-notificarindicador'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" fab dark small @click="openDialogNoti" v-bind="attrs" v-on="on">
                <v-icon dark>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Notificar Indicador</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.codigo`]="{ item }">
        <div v-auth-acl:conditional="'gh-ind-mis_ind-view'">
          <v-chip v-auth-acl-if color="primary" dark @click="viewIndicador(item.codigo)">{{ item.codigo }}</v-chip>
          <v-chip v-auth-acl-else color="primary" dark>{{ item.codigo }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.indicador`]="{ item }">
        <v-row>
          <v-col cols="8" class="d-flex align-center">
            <span> {{ item.indicador }} </span>
          </v-col>
          <v-col cols="3" class="d-flex align-center">
            <v-tooltip bottom v-auth-acl="'gh-ind-mis_ind-deleteindicador'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="red" class="mr-1" v-bind="attrs" v-on="on" @click="deleteItem(item)">
                  <v-icon small dark>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
            <v-tooltip v-if="item.isEditar == 1" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="editarItem(item.codigo)"
                >
                  <v-icon small dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip v-if="item.isEditar == 1" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" v-bind="attrs" v-on="on" @click="viewItem(item.codigo)">
                  <v-icon small dark>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.evaluacion`]="{ item }">
        <v-row>
          <v-col v-auth-acl="'gh-ind-mis_ind-inserteditfrequency'" cols="3" class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" v-bind="attrs" v-on="on" @click="openDialogEva(item.idindicador)">
                  <v-icon small dark>
                    {{ item.evaluacion == '' ? 'mdi-plus' : 'mdi-pencil' }}
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ item.evaluacion == '' ? 'Agregar' : 'Editar' }} </span>
            </v-tooltip>
          </v-col>
          <v-col cols="9">
            <span>{{ item.evaluacion }}</span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.fechadashboard`]="{ item }">
        {{ item.fechadashboard }}
      </template>
      <template v-slot:[`item.dashboard`]="{ item }">
        <v-row :style="`width: ${isAdmin ? 750 : 150}px;`">
          <v-col :cols="`${isAdmin ? 2 : 12}`" class="d-flex align-center justify-center">
            <v-tooltip v-if="item.evaluacion != ''" v-auth-acl="'gh-ind-mis_ind-inserteditdashboard'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" v-bind="attrs" v-on="on" @click="openDialogDash(item.idindicador)">
                  <v-icon small dark>
                    {{ item.dashboard == '' ? 'mdi-plus' : 'mdi-pencil' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ item.dashboard == '' ? 'Agregar' : 'Editar' }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.dashboard != ''" v-auth-acl="'gh-ind-mis_ind-viewdashboard'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="viewDashboard(item.indicador, item.dashboard)"
                >
                  <v-icon small dark>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="isAdmin" cols="10">
            <span>{{ item.dashboard }}</span>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-dialog v-auth-acl="'gh-ind-mis_ind-inserteditfrequency'" v-model="dialogEva" persistent max-width="400px">
      <v-card>
        <v-card-title class="py-3">
          Frecuencia de Evaluación
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-menu
                v-model="menuDateInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    append-icon="mdi-calendar"
                    readonly
                    :value="
                      fechaInicio
                        .split('-')
                        .reverse()
                        .join('-')
                    "
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  @input="menuDateInicio = false"
                  locale="es-es"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-menu
                ref="menuHoraInicio"
                v-model="menuHoraInicio"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="horaInicio"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuHoraInicio"
                  v-model="horaInicio"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menuHoraInicio.save(horaInicio)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select dense v-model="seleccion" :items="items" background-color="transparent" class="pa-0"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="error" text @click="closeDialogEva()">Cerrar</v-btn>
          <v-btn small color="primary" text @click="guardarEvaluacion()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-auth-acl="'gh-ind-mis_ind-inserteditdashboard'" v-model="dialogDash" persistent max-width="400px">
      <v-card>
        <v-card-title class="py-3">
          Dashboard
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="py-4">
            <v-col cols="12" class="py-0">
              <v-text-field v-model="url" label="URL"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialogDash()">Cerrar</v-btn>
          <v-btn color="primary" text @click="guardarDashboard()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSend" width="800" persistent>
      <v-card>
        <v-card-title class="py-3">
          Envió de notificación para responsables indicador
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8 pb-2">
          <v-row>
            <v-col cols="12" sm="12" class="py-0">
              <v-autocomplete
                dense
                v-model="responsablesIndicador"
                :items="itemsResponsables"
                item-text="name"
                item-value="code"
                no-data-text="No se encontraron datos"
                label="Responsables a notificar"
                placeholder="Responsables a notificar"
                prepend-icon="mdi-database-search"
                multiple
                return-object
                @input="searchInput = null"
                :search-input.sync="searchInput"
              >
                <template v-slot:selection="data">
                  <v-chip close small @click:close="removeIndicador(data.item.code)"> {{ data.item.name }} </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ `${data.item.dni} - ${data.item.idsubarea != '' ? data.item.idsubarea : 'OTROS'}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-text-field dense v-model="asunto" label="Asunto" prepend-icon="mdi-file"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-textarea
                outlined
                dense
                v-model="mensaje"
                label="Cuerpo del mensaje"
                prepend-icon="mdi-file"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn color="error" text @click="closeDialog()">Cerrar</v-btn>
          <v-btn color="info" text @click="notificar()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <DashboardView :object="objectViewDash" :dialog="dialogDashView" @closeDashView="closeDashView"></DashboardView>
    <IndicadorView
      v-auth-acl="'gh-ind-mis_ind-view'"
      :object="objectViewIndi"
      :dialog="dialogIndiView"
      @closeIndiView="closeIndiView"
    ></IndicadorView>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import Swal from 'sweetalert2';
import DashboardView from '../components/DashboardView';
import IndicadorView from '../components/IndicadorView';
import { v4 as uuidv4 } from 'uuid';
import { notificateIndicador } from '@/services/notificationService';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'MatrizLista',
  components: {
    FichaTecnicaFormulario: () => import('../components/FichaTecnicaFormulario'),
    DashboardView,
    IndicadorView
  },
  data: () => ({
    height: 130,
    permisoEditar: false,
    search: '',
    dialogSend: false,
    responsablesIndicador: [],
    itemsResponsables: [],
    asunto: '',
    mensaje: '',
    searchRes: '',
    menuDateInicio: false,
    menuHoraInicio: false,
    horaInicio: null,
    searchInput: null,
    fechaInicio: '',
    dialogEva: false,
    dialogDash: false,
    dialogDashView: false,
    dialogIndiView: false,
    url: '',
    dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dateRangeText: `${moment().format('YYYY-MM-DD')} ~ ${moment().format('YYYY-MM-DD')}`,
    showLoading: false,
    matrizService: null,
    headers: [
      {
        text: 'N°',
        value: 'numero',
        width: '100',
        class: 'header-items-fixed-ind nth-child1 primary',
        fixed: true
      },
      {
        text: 'INDICADOR',
        value: 'indicador',
        width: '350',
        class: 'header-items-fixed-ind nth-child2 primary',
        fixed: true
      },
      { text: 'ÁREA', value: 'area', width: '250', class: 'primary' },
      { text: 'SUBAREA', value: 'subarea', width: '250', class: 'primary' },
      { text: 'LIDER', value: 'lider', width: '200', class: 'primary' },
      { text: 'RESPONSABLE', value: 'responsable', width: '200', class: 'primary' },
      { text: 'CÓDIGO H.N', value: 'codigo', width: '250', class: 'primary' },
      { text: 'FUENTE DE DATOS', value: 'fuentedatos', width: '200', class: 'primary' },
      { text: 'FÓRMULA', value: 'formula', width: '250', class: 'primary' },
      { text: 'FRECUENCIA DE MEDICIÓN', value: 'medicion', width: '200', class: 'primary' },
      { text: 'FRECUENCIA DE EVALUACIÓN', value: 'evaluacion', width: '250', class: 'primary' },
      { text: 'FECHA DASHBOARD', value: 'fechadashboard', width: '200', class: 'primary', align: 'center' },
      { text: 'DASHBOARD', value: 'dashboard', width: '150', class: 'primary' },
      { text: 'FECHA CREACIÓN INDICADOR', value: 'fechacreacion', width: '200', class: 'primary' },
      { text: 'FECHA MODIFICACIÓN INDICADOR', value: 'fechamodificacion', width: '200', class: 'primary' }
    ],
    indicadores: [],
    items: [],
    seleccion: 'NO',
    idindicador: '',
    objectViewDash: {},
    objectViewIndi: {},
    objectEditIndi: {},
    dialogEdit: false,
    previewFicha: false,
    areas: [],
    itemsAreas: [],
    isAdmin: false,
    anio: '',
    listAnios: []
  }),
  computed: {
    checkAllAreas() {
      return (this.areas?.length ?? 0) === this.itemsAreas.length;
    },
    checkSomeAreas() {
      return (this.areas?.length ?? 0) > 0 && !this.checkAllAreas;
    },
    iconCheckboxAllAreas() {
      if (this.checkAllAreas) return 'mdi-close-box';
      if (this.checkSomeAreas) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    fechaInicio(val) {
      const dia = new Date(val).getDate() + 1;
      const diaSelect = new Date(val).getDay();
      const mesSelect = new Date(val).getMonth();
      var dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'Domingo'];
      var meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre'
      ];
      this.items = [
        { text: 'No se repite', value: 'NO' },
        { text: 'Todos los días', value: 'TO' },
        { text: 'Cada semana, el ' + dias[diaSelect], value: 'CS' },
        { text: 'Todos los meses, el primer ' + dias[diaSelect], value: 'TM' },
        { text: 'Anualmente, el ' + dia + ' de ' + meses[mesSelect], value: 'AE' }
      ];
    },
    async dateRange(val) {
      if (val.length === 1) {
        this.dateRangeText = `${val[0]} ~ `;
      }
      if (val.length === 2) {
        const min = Date.parse(val[0]) < Date.parse(val[1]) ? val[0] : val[1];
        const max = Date.parse(val[0]) > Date.parse(val[1]) ? val[0] : val[1];
        this.dateRangeText = `${min} ~ ${max}`;
        this.areas = [];
        await this.initialize();
        await this.cargarAreas();
      }
    }
  },
  methods: {
    removeIndicador(code) {
      const index = this.responsablesIndicador.findIndex((el) => el.code === code);
      if (index >= 0) this.responsablesIndicador.splice(index, 1);
    },
    async openDialogNoti() {
      await this.listJefes();
      this.dialogSend = true;
    },
    async listJefes() {
      this.showLoading = true;
      const res = await this.matrizService.get().execResource('listJefes');
      console.log(res, 'res');
      if (res.length > 0 && res[0].usuariosIndicadores != null)
        res[0].usuariosIndicadores.forEach((el) => {
          this.itemsResponsables.push({
            id: uuidv4(),
            code: el.idresponsable,
            dni: el.idtrabajador,
            idsubarea: el.idsubarea,
            name: el.nombresall,
            email: el.email,
            object: {}
          });
        });
      this.showLoading = false;
    },
    async notificar() {
      if (this.asunto == '') {
        await this.alertDialog('warning', 'Ingrese un asunto');
        return;
      }
      if (this.mensaje == '') {
        await this.alertDialog('warning', 'Ingrese un mensaje');
        return;
      }
      if (this.responsablesIndicador.length <= 0) {
        await this.alertDialog('warning', 'Ingrese al menos un responsable');
        return;
      }
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea notificar?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const resokrsaux = [];
        const participantes = [];
        let resokrNoti = [];
        let participantesNoti = [];

        this.responsablesIndicador.forEach((el) => {
          resokrsaux.push(el.code);
          participantes.push(el.email);
        });
        participantesNoti = [
          {
            email: participantes,
            subject: this.asunto,
            content: this.mensaje
          }
        ];
        resokrNoti = new Set(resokrsaux);
        for (const participant of resokrNoti) {
          await notificateIndicador(participant, this.mensaje);
        }
        const res = await this.matrizService.post().execResource('sendemail', {
          participantes: participantesNoti
        });
        if (res.status) {
          await this.alertDialog('success', res.data);
        } else {
          await this.alertDialog('error', res.data);
        }
        this.showLoading = false;
      }
    },
    closeDialog() {
      this.itemsResponsables = [];
      this.responsablesIndicador = [];
      this.asunto = '';
      this.mensaje = '';
      this.dialogSend = false;
    },
    async exportarbase() {
      this.showLoading = true;
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-MISINDICADORES-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      await this.matrizService.downloadResource(
        'exportar',
        {
          subarea: this.areas.toString().replaceAll('&', '%26'),
          anio: this.anio,
          token: token
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    async initialize() {
      this.showLoading = true;

      const res = await this.matrizService.get().execResource('matriz', {
        subarea: this.areas.toString().replaceAll('&', '%26'),
        anio: this.anio
      });
      if (res.length > 0) {
        this.indicadores = [];
        res.forEach((el, index) => {
          this.indicadores.push({
            numero: index + 1,
            idarea: el.idarea,
            idsubarea: el.idsubarea,
            indicador: el.indicador,
            lider: el.lider,
            responsable: el.responsable,
            idresponsablemetas: el.idresponsablemetas,
            codigo: el.idhojanormalizacion,
            idindicador: el.idindicador,
            area: el.area,
            subarea: el.subarea,
            formula: el.formacalculo,
            medicion: el.frecuenciamedicion,
            fuentedatos: el.fuentedatos,
            dashboard: el.dashboard,
            isEditar: el.isEditar,
            fechadashboard: el.fechadashboard != '' ? moment(el.fechadashboard).format('DD-MM-YYYY HH:mm') : '',
            fechacreacion: moment(el.indicadorfechaalter).format('DD-MM-YYYY HH:mm'),
            fechamodificacion: moment(el.indicadorfechacreacion).format('DD-MM-YYYY HH:mm'),
            evaluacion:
              el.frecuenciaevaluacion == ''
                ? ''
                : el.frecuenciaevaluacion[0].frecuencia +
                  ' ' +
                  el.frecuenciaevaluacion[0].dia +
                  ' ' +
                  el.frecuenciaevaluacion[0].horainicio,
            class: 'class-items-fixed-ind'
          });
          this.height = 500;
        });
      } else {
        this.indicadores = [];
      }

      this.showLoading = false;
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    openDialogEva(val) {
      this.idindicador = val;
      this.dialogEva = true;
    },
    closeDialogEva() {
      this.fechaInicio = moment().format('YYYY-MM-DD');
      this.horaInicio = moment().format('HH:mm');
      this.seleccion = 'NO';
      this.idindicador = '';
      this.dialogEva = false;
    },
    openDialogDash(val) {
      this.idindicador = val;
      this.dialogDash = true;
    },
    closeDialogDash() {
      this.url = '';
      this.seleccion = 'NO';
      this.idindicador = '';
      this.dialogDash = false;
    },
    closeDashView() {
      this.objectViewDash = {
        indicador: '',
        idindicador: '',
        url: ''
      };
      this.dialogDashView = false;
    },
    viewDashboard(indicador, url) {
      this.dialogDashView = true;
      this.objectViewDash = {
        indicador: indicador,
        url: url
      };
    },
    closeIndiView() {
      this.objectViewIndi = {};
      this.dialogIndiView = false;
    },
    closeEditView() {
      this.objectEditIndi = {};
      this.dialogEdit = false;
      this.previewFicha = false;
    },
    async viewIndicador(cod) {
      this.showLoading = true;
      const res = await this.matrizService.get().execResource('detalleIndicador', {
        idhojanormalizacion: cod
      });
      this.dialogIndiView = true;
      this.objectViewIndi = {
        codigo: cod,
        titulo: res[0].indicador + ' ' + res[0].codigo + ' ( ' + res[0].area + ' )',
        procesoEvaluado: res[0].proceso,
        nombreIndicador: res[0].indicador,
        queBusca: res[0].quesebusca,
        unidadMedida: res[0].unimedida,
        formaCalculo: res[0].formacalculo,
        detalleFormula: res[0].detalleformula,
        consideracion1: res[0].consideraciones.split('|')[0],
        consideracion2: res[0].consideraciones.split('|')[1],
        consideracion3: res[0].consideraciones.split('|')[2],
        fuenteDatos: res[0].fuentedatos,
        datosHistoricos: res[0].datahistorica,
        metaPropuesta: res[0].meta,
        responsablesMetas: res[0].responsablemetas,
        frecuenciaMedicion: res[0].frecuenciamedicion,
        frecuenciaEvaluacion:
          res[0].frecuenciaevaluacion == ''
            ? ''
            : res[0].frecuenciaevaluacion[0].frecuencia +
              ' ' +
              res[0].frecuenciaevaluacion[0].dia +
              ' ' +
              res[0].frecuenciaevaluacion[0].horainicio,
        responsableMedicion: res[0].responsablemedicion,
        responsableEvalucion: res[0].responsableevaluacion,
        observaciones: res[0].observaciones,
        elaboro: res[0].responsableelaboro,
        reviso: res[0].responsablerevision,
        aprobo: res[0].responsableaprobacion
      };
      this.showLoading = false;
    },
    async detalleIndicador(cod) {
      const res = await this.matrizService.get().execResource('detalleIndicador', {
        idhojanormalizacion: cod
      });
      this.objectEditIndi = {
        codigo: cod,
        titulo: res[0].indicador + ' ' + res[0].codigo + ' ( ' + res[0].area + ' )',
        procesoEvaluado: res[0].proceso,
        nombreIndicador: res[0].indicador,
        queBusca: res[0].quesebusca,
        unidadMedida: res[0].unimedida,
        formaCalculo: res[0].formacalculo,
        detalleFormula: res[0].detalleformula,
        consideracion1: res[0].consideraciones.split('|')[0],
        consideracion2: res[0].consideraciones.split('|')[1],
        consideracion3: res[0].consideraciones.split('|')[2],
        fuenteDatos: res[0].fuentedatos,
        datosHistoricos: res[0].datahistorica,
        metaPropuesta: res[0].meta,
        responsablesMetas: res[0].idresponsablemetas,
        frecuenciaMedicion: res[0].frecuenciamedicion,
        responsableMedicion: res[0].idresponsablemedicion,
        responsableEvalucion: res[0].idresponsableevaluacion,
        observaciones: res[0].observaciones,
        elaboro: res[0].idelaboro,
        reviso: res[0].idresponsablerevision,
        aprobo: res[0].idresponsableaprobacion
      };
    },
    async editarItem(cod) {
      this.showLoading = true;
      await this.detalleIndicador(cod);
      this.dialogEdit = true;
      this.showLoading = false;
    },
    async viewItem(cod) {
      this.showLoading = true;
      await this.detalleIndicador(cod);
      this.dialogEdit = true;
      this.previewFicha = true;
      this.showLoading = false;
    },
    async deleteItem({ idindicador }) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea eliminar el indicador?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const restable = await this.matrizService.delete().execResource('eliminar', { id: idindicador });
        this.showLoading = false;
        if (restable.status) {
          await this.alertDialog('success', restable.data);
          await this.initialize();
        } else {
          await this.alertDialog('error', restable.data);
        }
      }
    },
    async guardarEvaluacion() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea guardar la evaluación?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        let res;
        this.showLoading = true;
        res = await this.matrizService.put().execResource('guardarEvaluacion', {
          fechaincio: this.fechaInicio,
          horainicio: this.horaInicio,
          repetir: this.seleccion,
          idindicador: this.idindicador
        });
        this.showLoading = false;
        if (res.status) {
          await this.alertDialog('success', res.data);
          await this.initialize();
          this.closeDialogEva();
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async guardarDashboard() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea guardar el dashboard?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        let res;
        this.showLoading = true;
        res = await this.matrizService.put().execResource('guardarDashboard', {
          url: this.url,
          idindicador: this.idindicador
        });
        this.showLoading = false;
        if (res.status) {
          await this.alertDialog('success', res.data);
          await this.initialize();
          this.closeDialogDash();
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    /*
    async permisos() {
      const res = await this.matrizService.get().execResource('permisos');
      this.permisoEditar = res[0].editarhojanormalizacion == 1;
    },
    */
    selectAllAreas() {
      this.$nextTick(() => {
        if (this.checkAllAreas) {
          this.areas = [];
        } else {
          this.areas = this.itemsAreas.map((element) => {
            return element.value;
          });
        }
      });
    },
    async cargarAreas() {
      this.itemsAreas = [];
      this.indicadores.forEach((el) => {
        let exist = -1;
        exist = this.itemsAreas.findIndex((el2) => el2.value == el.idsubarea);
        if (exist == -1 && el.idsubarea != '') {
          this.itemsAreas.push({ value: el.idsubarea, text: el.subarea });
        }
      });
      this.itemsAreas.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      this.areas = this.itemsAreas.map((el) => el.value);
    },
    async intervaloFechas() {
      let currentYear = moment().year(); // año actual
      let currentMonth = moment().month(); // año actual
      let endMonthDays = moment([currentYear, currentMonth]).daysInMonth(); // número de días en el mes
      this.dateRange = [
        moment(currentYear + '-' + currentMonth + '-' + 1).format('YYYY-MM-DD'),
        moment(currentYear + '-' + currentMonth + '-' + endMonthDays).format('YYYY-MM-DD')
      ];
      this.dateRange = [
        moment([currentYear, currentMonth, 1]).format('YYYY-MM-DD'),
        moment([currentYear, currentMonth, endMonthDays]).format('YYYY-MM-DD')
      ];
    },
    async cargarAnios() {
      this.listAnios = [{ value: '', text: 'TODOS' }];
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.matrizService.get().execResource('cargarAnios', {
        idempresa: emp
      });
      res.forEach((el) => {
        this.listAnios.push({ value: el.anio, text: el.anio });
      });
      this.showLoading = false;
    },
    async cambioAnio() {
      this.areas = [];
      await this.initialize();
      await this.cargarAreas();
    }
  },
  async mounted() {},
  async created() {
    this.isAdmin = this.$store.state.permission4x.role === 'admin';
    this.showLoading = true;
    this.fechaInicio = moment().format('YYYY-MM-DD');
    this.horaInicio = moment().format('HH:mm');
    this.matrizService = this.$httpService(new MatrizService(), this);
    // await this.permisos();
    await this.cargarAnios();
    await this.initialize();
    await this.cargarAreas();
    this.showLoading = false;
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
