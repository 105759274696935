class MatrizService {
  constructor() {}
  resources = () => ({
    matriz: {
      uri: `/v1/indicadores`,
      method: ['get']
    },
    exportar: {
      uri: `/v1/exportarindicadores`,
      method: ['get']
    },
    guardarEvaluacion: {
      uri: `/v1/indicadores/frecuenciaevaluacion`,
      method: ['put']
    },
    guardarDashboard: {
      uri: `/v1/indicadores/dashboard`,
      method: ['put']
    },
    detalleIndicador: {
      uri: `/v1/indicadores/detalle`,
      method: ['get']
    },
    descargarindicador: {
      uri: `/v1/indicadores/downloadhn`,
      method: ['get']
    },
    eliminar: {
      uri: `/v1/indicadores/eliminar`,
      method: ['delete']
    },
    listJefes: {
      uri: `/v1/usuario/usuarios-modulos`,
      method: ['get']
    },
    sendemail: {
      uri: `/v1/notificate/comites`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    responsables: {
      uri: `/v1/responsables`,
      method: ['get']
    },
    editar: {
      uri: `/v1/indicadores/editar`,
      method: ['put'],
      headers: { 'Content-Type': 'application/json' }
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    },
    cargarAnios: {
      uri: `/v1/indicadores/anios`,
      method: ['get']
    }
  });
}

export default MatrizService;
